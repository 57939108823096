<template>
    <div class="card card-widget">
        <div class="card-body">
            <label>{{ $t('general.cargamaximadiaria') }}</label>
            <div class="input-group input-group-sm">
                <input v-model="carga_max" type="number" class="form-control" >
                <span class="input-group-append">
                    <button type="button" @click="guardar()" class="btn btn-info btn-flat">{{ $t('general.guardar') }}</button>
                </span>
            </div>   
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';            
export default {
    data() {
        return {
            modificado: false,
            carga_max: '',
        }
    },
    methods: {
        async obtenerCargaMaxima() {
            /* get  modulos/pwgsapi/index.php/usuarios/carga-maxima */
            const api = new PwgsApi;
            this.carga_max = await api.get('usuarios/carga-maxima');
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/usuarios/carga-maxima */
            try {
                const api = new PwgsApi;
                const subidadatos = { carga_maxima_diaria: this.carga_max }
                await api.put('usuarios/carga-maxima', subidadatos);
                this.modificado = true;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
        
    },
    mounted() {
        this.obtenerCargaMaxima();
    },
    watch: {
        id() {
            this.obtenerCargaMaxima();
        }
    }
}
</script>